import Chart from "react-apexcharts";
import React, { FC } from "react";
import { install } from "resize-observer";
import { ErrorBoundary } from "react-error-boundary";

if (!window.ResizeObserver) install();

const myErrorHandler = (error: Error) => {
  console.log(error);
};

const ChargingGraph: FC<any> = (props) => {
  const { graphData } = props;

  const options: any = {
    tooltip: {
      enabled: false,
    },
    chart: {
      id: "area-datetime",
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      tickAmount:
        graphData.length > 0 ? graphData[graphData.length - 1][0] / 60 : 0,
      labels: {
        formatter: function (value) {
          return Math.floor(value / 60);
        },
      },
    },
    annotations: {
      yaxis: [
        {
          borderColor: "#999",
          opposite: true,
          type: "numeric",
          label: {
            show: true,
            text: "mins",
            offsetY: 45,
            style: {
              align: "right",
              color: "#fff",
              background: "#2cae1e",
            },
          },
        },
      ],
      xaxis: [
        {
          borderColor: "#999",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "Energy (kWh)",
            offsetX: -45,
            offsetY: -15,
            style: {
              color: "#fff",
              background: "#2cae1e",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      show: false,
      size: 0,
      style: "hollow",
    },

    fill: {
      type: "gradient",
      gradient: {
        stops: [0, 100],
      },
    },
    stroke: {
      curve: "straight",
    },
    colors: ["#d73a1f"],
  };
  const series: any = [
    {
      data: graphData,
    },
  ];
  return (
    <ErrorBoundary FallbackComponent={() => <></>} onError={myErrorHandler}>
      <div id="chart">
        <div id="chart-timeline">
          <Chart
            options={options}
            series={series}
            type="area"
            height={window.innerHeight * 0.4}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ChargingGraph;
