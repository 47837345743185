export const getDNAPaymentScript = (isTestMode: boolean): string => {
  return isTestMode
    ? "https://test-pay.dnapayments.com/checkout/payment-api.js"
    : "https://pay.dnapayments.com/checkout/payment-api.js";
};

export const getDNAPaymentScriptIntegrity = (isTestMode: boolean): string => {
  return isTestMode
    ? "sha256-z27fIyoBGu/jlBtWNBta9k6bAfpIqeTuEVxceOXV53k="
    : "sha256-A3y2uxmrya0WIZUTucuGnBjtT4PZ2+joczNt+ry+JOM=";
};
