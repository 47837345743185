import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: require("../../assets/fonts/Roboto-Bold.ttf"),
    },
  ],
});
const styles = StyleSheet.create({
  page: { backgroundColor: "white", flex: 1, padding: 0 },
  logo: { alignSelf: "center", marginTop: 20 },
  logoImg: { height: 30 },
  Header: {
    flexDirection: "row",
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  width_90: { width: "90%" },
  width_40: { width: "40%" },
  width_50: { width: "50%" },
  width_45: { width: "45%" },
  width_30: { width: "30%" },
  width_20: { width: "20%" },
  width_25: { width: "25%" },
  width_10: { width: "10%" },
  width_15: { width: "15%" },
  width_5: { width: "5%" },
  width_8: { width: "8%" },
  boldText: { fontFamily: "Roboto", fontWeight: 800, fontSize: 10 },
  boldText_default: { fontWeight: 800, fontSize: 10 },
  date_section: { marginLeft: 20 },
  fontSize_10: { fontSize: 10 },
  fontSize_12: { fontSize: 12 },
  fontSize_10_Bold: { fontSize: 10, fontWeight: 700 },
  HeaderSubPart: {
    flexDirection: "row",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 15,
    paddingTop: 10,
  },
  Hr: {
    borderTopWidth: 1,
    borderColor: "black",
    marginLeft: 30,
    marginRight: 30,
  },
  ContentDiv: { paddingTop: 10, paddingLeft: 30, paddingRight: 30 },
  ContentDiv_Title: { fontSize: 10, marginBottom: 10 },
  ContentDiv_table: { flexDirection: "row", marginBottom: 5 },
  ContentDiv_table_td: { flexDirection: "row", marginTop: 1 },
  ContentDiv_Sub_Total: { flexDirection: "row", marginTop: 20 },
  ContentDiv_Total: { flexDirection: "row", marginTop: 10 },
  ContentDiv_Bottom_Text: { fontSize: 10, marginBottom: 10, marginTop: 10 },
  Footer_Div: { paddingTop: 15, paddingLeft: 30, paddingRight: 30 },
  Footer_Address: { fontSize: 10, textAlign: "center" },
  Footer_SubAddress: {
    fontSize: 10,
    textAlign: "center",
    marginTop: 2,
    marginBottom: 2,
  },
  Footer_Contact: { paddingTop: 15, paddingLeft: 30, paddingRight: 30 },
  Footer_Text: { fontSize: 8, textAlign: "center" },
  fontSize_10_align_right: { fontSize: 10, textAlign: "right" },
  fontSize_12_align_right: { fontSize: 12, textAlign: "right" },
  boldText_align_right: {
    fontFamily: "Roboto",
    fontWeight: 800,
    fontSize: 10,
    textAlign: "right",
  },
  boldText_align_right_default: {
    fontWeight: 800,
    fontSize: 10,
    textAlign: "right",
  },
});

const VATReceipt = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logo}>
          <Image
            source={require("../../assets/images/logo-instavolt.png")}
            style={styles.logoImg}
          />
        </View>
        <View style={styles.Header}>
          <View style={styles.width_40}>
            <Text>{props.t("history.receipt.title")}</Text>
          </View>
          <View style={styles.width_45}></View>
          <View style={styles.width_15}>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={
                  props.isDisplay && props.isDisplay === true
                    ? styles.boldText_default
                    : styles.boldText
                }
              >
                {props.t("history.receipt.header.date")}:{" "}
              </Text>
              <Text style={styles.fontSize_10}>{props.data.date} </Text>
            </View>
          </View>
        </View>
        <View style={styles.HeaderSubPart}>
          <View style={styles.width_40}>
            <Text style={styles.fontSize_10_Bold}>
              {" "}
              {props.t("history.receipt.header.note")}
            </Text>
          </View>
          <View style={styles.width_45}></View>
          <View style={styles.width_15}>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={
                  props.isDisplay && props.isDisplay === true
                    ? styles.boldText_default
                    : styles.boldText
                }
              >
                {props.t("history.receipt.header.vat_number")}:{" "}
              </Text>
            </View>
            <Text style={styles.fontSize_10}>{props.data.vat_number}</Text>
          </View>
        </View>
        <View style={styles.Hr}></View>
        <View style={styles.ContentDiv}>
          <Text style={styles.ContentDiv_Title}>
            {props.t("history.receipt.content.summary")}:
          </Text>
          <View style={styles.ContentDiv_table}>
            <View style={styles.width_45}>
              <Text style={styles.fontSize_12}>
                {props.t("history.receipt.content.table.header.transaction_id")}
              </Text>
            </View>
            <View style={styles.width_20}>
              <Text style={styles.fontSize_12}>
                {props.t("history.receipt.content.table.header.station_name")}
              </Text>
            </View>
            <View style={styles.width_15}>
              <Text style={styles.fontSize_12}>
                {props.t("history.receipt.content.table.header.start")}
              </Text>
            </View>
            <View style={styles.width_15}>
              <Text style={styles.fontSize_12}>
                {props.t("history.receipt.content.table.header.end")}
              </Text>
            </View>
            <View style={styles.width_10}>
              <Text style={styles.fontSize_12}>
                {props.t("history.receipt.content.table.header.usage")}
              </Text>
            </View>
            <View style={styles.width_10}>
              <Text style={styles.fontSize_12}>
                {props.t("history.receipt.content.table.header.unit")}
              </Text>
            </View>
            <View style={styles.width_8}>
              <Text style={styles.fontSize_12_align_right}>
                {props.t("history.receipt.content.table.header.value")}
              </Text>
            </View>
          </View>
          {props.data &&
            props.data.charge_details &&
            props.data.charge_details.map((item, index) => (
              <View style={styles.ContentDiv_table_td} key={index}>
                <View style={styles.width_45}>
                  <Text style={styles.fontSize_10}>{item.transaction_id}</Text>
                </View>
                <View style={styles.width_20}>
                  <Text style={styles.fontSize_10}>{item.station_name}</Text>
                </View>
                <View style={styles.width_15}>
                  <Text style={styles.fontSize_10}>
                    {moment(item.start).format("DD/MM/YYYY HH:mm:ss")}
                  </Text>
                </View>
                <View style={styles.width_15}>
                  <Text style={styles.fontSize_10}>
                    {item.end !== "" &&
                      moment(item.end).format("DD/MM/YYYY HH:mm:ss")}
                  </Text>
                </View>
                <View style={styles.width_10}>
                  <Text style={styles.fontSize_10}>{item.usage}</Text>
                </View>
                <View style={styles.width_10}>
                  <Text style={styles.fontSize_10}>{item.unit}</Text>
                </View>
                <View style={styles.width_8}>
                  <Text style={styles.fontSize_10_align_right}>
                    &pound; {parseFloat(item.value).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}
          <View style={styles.ContentDiv_Sub_Total}>
            <View style={styles.width_90}>
              <Text style={styles.fontSize_10}>
                {props.t("history.receipt.content.total_before_vat")}
              </Text>
            </View>
            <View style={styles.width_10}>
              <Text style={styles.fontSize_10_align_right}>
                &pound; {props.data.net_amount}
              </Text>
            </View>
          </View>

          {props.data &&
            props.data.vat_details &&
            props.data.vat_details.map((item) => (
              <>
                <View style={styles.ContentDiv_Total}>
                  <View style={styles.width_90}>
                    <Text style={styles.fontSize_10}>
                      {`${props.t("history.receipt.content.vat")} ${
                        item.vat_rate
                      }%`}
                    </Text>
                  </View>
                  <View style={styles.width_10}>
                    <Text style={styles.fontSize_10_align_right}>
                      &pound; {item.total_vat_amount}
                    </Text>
                  </View>
                </View>
              </>
            ))}

          <View style={styles.ContentDiv_Total}>
            <View style={styles.width_90}>
              <Text
                style={
                  props.isDisplay && props.isDisplay === true
                    ? styles.boldText_default
                    : styles.boldText
                }
              >
                {props.t("history.receipt.content.total_fee")}
              </Text>
            </View>
            <View style={styles.width_10}>
              <Text
                style={
                  props.isDisplay && props.isDisplay === true
                    ? styles.boldText_align_right_default
                    : styles.boldText_align_right
                }
              >
                &pound; {props.data.total_fee}
              </Text>
            </View>
          </View>
          <Text style={styles.ContentDiv_Bottom_Text}>
            {props.t("history.receipt.content.thanks_message")}
          </Text>
        </View>
        <View style={styles.Hr}></View>
        <View style={styles.Footer_Div}>
          <Text style={styles.Footer_Address}>
            {props.t("history.receipt.footer.title")}
          </Text>
          <Text style={styles.Footer_SubAddress}>
            {props.t("history.receipt.footer.address")}
          </Text>
          <Text style={styles.Footer_SubAddress}>
            {props.t("history.receipt.footer.telephone")}: {props.data.phone}{" "}
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
            {props.t("history.receipt.footer.email")}: {props.data.email}
          </Text>
        </View>
        <View style={styles.Footer_Contact}>
          <Text style={styles.Footer_Text}>
            {props.t("history.receipt.footer.note")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default VATReceipt;
