import React from "react";
import { FC } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ICommonProps } from "../types/propTypes";

const MainLayout: FC<ICommonProps> = (props) => {
  const { children, t } = props;
  return (
    <div className="wrapper">
      <header className="header">
        <div className="container-xl">
          <div className="row no-gutters align-items-center">
            <div className="col">
              <Navbar expand="lg">
                <Navbar.Brand as={Link} to="/" title={t("navbar.title")}>
                  <img
                    src={require("../assets/images/logo.png")}
                    alt={t("navbar.title")}
                  />
                </Navbar.Brand>
              </Navbar>
            </div>
          </div>
        </div>
      </header>
      <main className="main-content">
        <div className="container-xl">
          <div className="card mb-4">{children}</div>
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
