import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

const AsyncSelectBox = (props) => {
  const { label, labelClass = "", name, value, isMulti, ...rest } = props;
  return (
    <React.Fragment>
      {label && <label className={labelClass}>{label}</label>}
      <AsyncSelect
        name={name}
        class="form-control"
        value={value}
        loadOptions={(value) => props.loadOptions(value)}
        isMulti={isMulti}
        cacheOptions={props.cacheOptions}
        isInvalid={props.isInvalid}
        onChange={(value) => props.onChange(value)}
        onBlur={() => props.onBlur && props.onBlur()}
        placeholder={props.placeholder}
        menuPlacement="auto"
        isClearable={true}
        components={{
          Input: (props) => <components.Input {...props} maxLength={10} />,
        }}
        {...rest}
      />
      {props.touched && props.errors && props.errors !== "required" && (
        <div className="pb-0 invalid-feedback d-block invalid-feedback">
          {props.touched && props.errors}{" "}
        </div>
      )}
    </React.Fragment>
  );
};

export default AsyncSelectBox;
