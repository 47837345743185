import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: require("../../assets/fonts/Roboto-Bold.ttf"),
    },
  ],
});
const styles = StyleSheet.create({
  page: { backgroundColor: "white", flex: 1, padding: 0 },
  logo: { alignSelf: "center", marginTop: 20, marginBottom: 20 },
  logoImg: { height: 30 },
  Header: { flexDirection: "row", paddingTop: 20, paddingBottom: 20 },
  Hr: {
    borderTopWidth: 1,
    borderColor: "black",
    marginLeft: 30,
    marginRight: 30,
  },
  Message: {
    fontSize: 16,
    textAlign: "center",
    marginLeft: 30,
    marginRight: 30,
  },
});

const NoDataReceipt = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logo}>
          <Image
            source={require("../../assets/images/logo-instavolt.png")}
            style={styles.logoImg}
          />
        </View>
        <View style={styles.Hr}></View>
        <View style={styles.Header}>
          <Text style={styles.Message}>
            {props.t("history.receipt.content.vatReceiptNotGenerated")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default NoDataReceipt;
