import React, { useEffect } from "react";
import { FC, useState } from "react";
import CommonService from "../../services/common/common-service";
import { ICommonProps } from "../../types/propTypes";
import { Common } from "../../utils/constants";
import AuthoriseSession from "./AuthoriseSession";
import ChargingProgress from "./ChargingProgress";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useParams } from "react-router-dom";
import { ChargeSession } from "../../models/charging";
const commonService = new CommonService();
const Charging: FC<ICommonProps> = (props) => {
  const [chargingStarted, setChargingStarted] = useState<boolean | null>(false);
  const [webSocketURI, setWebSocketURI] = useState<string>("");
  const [socketClient, setsocketClient] = useState<any>(null);
  const [sessionDetails, setSessionDetails] = useState<ChargeSession | null>(
    null
  );
  const [isPaymentRetry, setIsPaymentRetry] = useState<boolean>(false);
  const [isSessionCompleted, setIsSessionCompleted] = useState<boolean>(false);
  const { code, invoice_id } = useParams();
  const token = localStorage.getItem(`SESSION_TOKEN_${code}`);

  const openChargingProgressPage = (isPaymentRetry, isSessionCompleted) => {
    setChargingStarted(true);
    if (isPaymentRetry) {
      setIsPaymentRetry(true);
      setIsSessionCompleted(false);
    }
    if (isSessionCompleted) {
      setIsPaymentRetry(false);
      setIsSessionCompleted(true);
    }
  };

  useEffect(() => {
    getWebsocketURL();
  }, []);

  useEffect(() => {
    if (webSocketURI) {
      if (socketClient) {
        try {
          socketClient.disconnect();
        } catch (err) {
          console.error(err);
        }
      }

      const client = new W3CWebSocket(
        webSocketURI.concat(`?token=${token}&invoice_id=${invoice_id}`)
      );
      client.onopen = () => {
        console.log("WebSocket Client Connected");
        setsocketClient(client);
      };
      client.onmessage = (response) => {
        if (response && response.data) {
          const session = JSON.parse(response.data);

          setSessionDetails(session);
        }
      };
    }
  }, [webSocketURI]);

  const getWebsocketURL = async () => {
    await commonService
      .getConfigurationDetail({
        code: Common.ConfigurationKey.WEB_PAY_WEBSOCKET_URL,
      })
      .then((res) => {
        setWebSocketURI(res.Data[0]?.value);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  return !chargingStarted ? (
    <AuthoriseSession
      {...props}
      openChargingProgressPage={(isPaymentRetry, isSessionCompleted) =>
        openChargingProgressPage(isPaymentRetry, isSessionCompleted)
      }
      socketClient={socketClient}
    />
  ) : (
    <ChargingProgress
      {...props}
      socketClient={socketClient}
      chargeSession={sessionDetails}
      isPaymentRetry={isPaymentRetry}
      isSessionCompleted={isSessionCompleted}
    />
  );
};
export default Charging;
