import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { ICharityDonationModalProps } from "../../types/propTypes";

const CharityDonationModal: FC<ICharityDonationModalProps> = (props) => {
  const {
    t,
    showModal,
    onHide,
    charityImage,
    charityLink,
    charityText,
    isCharityTextTruncate,
    donationNote,
    charityAmount,
  } = props;

  return (
    <>
      <Modal show={showModal} onHide={() => onHide()} className="charity-modal">
        <Modal.Header className="justify-content-center charity-modal-header">
          <Modal.Title className="font-weight-bold charity-modal-title">
            {t("CharityDonationModal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <div className="media donate-block ">
              {charityImage !== "" && (
                <img className="mr-3" src={charityImage} alt="" />
              )}
              <div className="media-body text-left">
                <p className="mt-0 pb-0 charity-details">
                  {charityText} {isCharityTextTruncate && <br />}
                  <a
                    href={charityLink}
                    title={t("CharityDonationModal.label.seeMore") as string}
                    className="read-more"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>{t("CharityDonationModal.label.seeMore")}</small>
                  </a>
                </p>
              </div>
            </div>
          </div>
          {donationNote !== "" && (
            <div className="form-group ">
              <span>
                <strong className="note-label">
                  {t("CharityDonationModal.label.note")}
                </strong>
                {donationNote}
              </span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="padding-bottom-10">
          <button
            className="btn btn-green"
            onClick={() => {
              props.openDNAPaymentInterface(true);
            }}
            title={`${t("CharityDonationModal.label.yesDonate")}${
              charityAmount ? ` £${charityAmount.toFixed(2)}` : ""
            }`}
          >
            {`${t("CharityDonationModal.label.yesDonate")}${
              charityAmount ? ` £${charityAmount.toFixed(2)}` : ""
            }`}
          </button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              props.openDNAPaymentInterface(false);
            }}
            title={t("CharityDonationModal.label.noThanks") as string}
          >
            {t("CharityDonationModal.label.noThanks")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CharityDonationModal;
