import { ITransactionDetails } from "models/payment";
import StandardResponse from "models/standard-response";
import BaseService from "services/base-service";

export default class PaymentService {
  getTransactionDetails = async (
    invoiceID
  ): Promise<StandardResponse<ITransactionDetails>> => {
    try {
      const res = await BaseService({
        url: `web-pay/payment/invoice/${invoiceID}`,
        method: "GET",
        data: { hideSpinner: false },
      });

      return new StandardResponse<ITransactionDetails>(
        res.status,
        res.data.data,
        res.data.message,
        null
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
  getToken = async (data): Promise<StandardResponse<string>> => {
    try {
      const res = await BaseService({
        url: `web-pay/charge-sessions/get-token`,
        method: "POST",
        data,
      });

      return new StandardResponse<string>(
        res.status,
        res.data.data,
        res.data.message,
        null
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createPaymentInvoice = async (
    connector_id: number,
    email: string,
    include_charity: boolean
  ): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: "/web-pay/payment/create/invoice",
        method: "POST",
        data: { email, connector_id, include_charity },
      });
      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        null
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
