import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Form, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { getDNAPaymentScript } from "../../utils/payment";
import { Common } from "../../utils/constants";
import {
  IConfiguration,
  IStartChargeFormField,
} from "../../models/start-charge";
import { ReactstrapInput } from "../../components/reactstrap-formik";
import StandardResponse from "../../models/standard-response";
import SearchPageService from "../../services/search-page/search-page-service";
import PaymentService from "../../services/payment/payment-service";
import CommonService from "../../services/common/common-service";
import { TFunction } from "i18next";
import "./style.css";
import { IPaymentInvoice } from "../../models/payment";
import CharityDonationModal from "./CharityDonationModal";

const searchPageService = new SearchPageService();
const paymentService = new PaymentService();
const commonService = new CommonService();

export const StartChargeSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string()
      .matches(
        Common.RegularExpression.EmailRegularExpression,
        t("startCharge.errors.inValidEmail")
      )
      .required("required"),
    tnc: Yup.boolean()
      .oneOf([true], t("startCharge.errors.acceptTnc"))
      .required("required"),
  }) as Yup.SchemaOf<IStartChargeFormField>;
};

interface iStartChargeProps {
  t: TFunction;
}

const StartCharge: FC<iStartChargeProps> = (props) => {
  const { t } = props;

  const [stationDetails, setStationDetails] = useState<any | null>(null);
  const [connectorDetails, setConnectorDetails] = useState<any | null>(null);
  const [preAuthAmount, setPreAuthAmount] = useState<number>(0);
  const [tncLink, setTncLink] = useState<string>("");

  const [allowCharity, setAllowCharity] = useState<boolean>(false);
  const [charityText, setCharityText] = useState<string>("");
  const [isCharityTextTruncate, setIsCharityTextTruncate] =
    useState<boolean>(false);
  const [charityLink, setCharityLink] = useState<string>("");
  const [charityImage, setCharityImage] = useState<string>("");
  const [donationType, setDonationType] = useState<string>("");
  const [donationNote, setDonationNote] = useState<string>("");
  const [donationValue, setDonationValue] = useState<number>(0);
  const [showCharityModal, setShowCharityModal] = useState<boolean>(false);
  const [charityAmount, setCharityAmount] = useState<number>(0);
  const [email, setEmail] = useState<string>("");

  const location = useLocation();
  const { code, connector } = useParams();
  const navigate = useNavigate();

  const initialState = {
    email: "",
    tnc: false,
  };

  useEffect(() => {
    if (
      connector &&
      +connector > 0 &&
      stationDetails &&
      stationDetails.connectors &&
      stationDetails.connectors.length > 0
    ) {
      const filteredConnector = stationDetails.connectors.filter(
        (con) => con.id == +connector
      );
      if (filteredConnector.length > 0) {
        setConnectorDetails(filteredConnector[0]);
      }
    }
  }, [stationDetails]);

  useEffect(() => {
    getPreAuthAmount();
    getTnc();

    const getConfigData = async (code: string) => {
      let charityDonationConfig: Array<any> = [];
      await commonService
        .getConfigurationDetail({ code })
        .then(async (res) => {
          charityDonationConfig = res.Data;
          if (charityDonationConfig.length > 0) {
            let isCharityDonation: boolean = false;
            let charityImage = "";
            let charityLink = "";
            let charityText = "";
            let isCharityTextTruncate = false;
            let truncatedCharityText = "";
            let donationType = "";
            let donationValue: number | null = 0;
            let donationNote = "";

            const charityConfig: IConfiguration = charityDonationConfig.find(
              (x: any) =>
                x.reference_code === Common.ConfigurationKey.CHARITY_DONATION
            );
            let charityDonation = charityConfig.value
              ? charityConfig.value
              : "";
            if (charityDonation.trim().toLowerCase() === "false") {
              isCharityDonation = false;
            } else {
              isCharityDonation = true;
            }
            if (isCharityDonation) {
              let charityImageConfig = charityDonationConfig.find(
                (x) =>
                  x.reference_code ===
                  Common.ConfigurationKey.CHARITY_DONATION_IMAGE
              );
              charityImage =
                charityImageConfig && charityImageConfig.value
                  ? charityImageConfig.value
                  : "";

              let charityLinkConfig = charityDonationConfig.find(
                (x) =>
                  x.reference_code ===
                  Common.ConfigurationKey.CHARITY_DONATION_LINK
              );
              charityLink =
                charityLinkConfig && charityLinkConfig.value
                  ? charityLinkConfig.value
                  : "";

              let charityTextConfig = charityDonationConfig.find(
                (x) =>
                  x.reference_code ===
                  Common.ConfigurationKey.CHARITY_DONATION_TEXT
              );
              truncatedCharityText =
                charityTextConfig && charityTextConfig.value
                  ? charityTextConfig.value
                  : "";
              if (truncatedCharityText.length > 500) {
                truncatedCharityText = truncatedCharityText.slice(0, 500);
                truncatedCharityText = `${truncatedCharityText}...`;
                isCharityTextTruncate = true;
              }

              const donationTypeConfig = charityDonationConfig.find(
                (x) =>
                  x.reference_code ===
                  Common.ConfigurationKey.DONATION_TOPUP_OR_ROUNDUP
              );
              donationType = donationTypeConfig?.value;

              const donationValueConfig = charityDonationConfig.find(
                (x) =>
                  x.reference_code === Common.ConfigurationKey.DONATION_VALUE
              );
              donationValue = Number(
                Number(donationValueConfig?.value).toFixed(2)
              );

              const donationNoteConfig: any = charityDonationConfig.find(
                (x: any) =>
                  x.reference_code ===
                  Common.ConfigurationKey.WEB_PAY_DONATION_NOTE
              );
              donationNote = donationNoteConfig?.value;
            }

            setAllowCharity(isCharityDonation);
            setDonationType(donationType);
            setDonationValue(donationValue);
            setCharityImage(charityImage);
            setCharityText(truncatedCharityText);
            setIsCharityTextTruncate(isCharityTextTruncate);
            setCharityLink(charityLink);
            setDonationNote(donationNote);
          }
        })
        .catch((m) => {
          toast.error(m.toString());
        });
    };

    getConfigData(Common.ConfigurationKey.DONATION);

    if (code && code.length > 10) {
      toast.error(t("stationDetails.error.invalidCode").toString());
      navigate("/");
    } else {
      if (location.state) {
        setStationDetails(location.state);
      } else {
        getStationDetailsByCode(code);
      }
    }
  }, []);

  const getPreAuthAmount = async () => {
    await commonService
      .getConfigurationDetail({
        code: Common.ConfigurationKey.WEB_PAY_PRE_AUTH_AMOUNT,
      })
      .then((res) => {
        setPreAuthAmount(res.Data[0]?.value);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  const getTnc = async () => {
    await commonService
      .getConfigurationDetail({
        code: Common.ConfigurationKey.WEB_PAY_TERM_AND_CONDITIONS,
      })
      .then((res) => {
        setTncLink(res.Data[0]?.value);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  const getStationDetailsByCode = async (code) => {
    await searchPageService
      .getStationDetailsByCode(code)
      .then((res: StandardResponse<any>) => {
        if (res.Data && Object.keys(res.Data).length !== 0) {
          setStationDetails(res.Data);
        } else {
          toast.error(t("stationDetails.error.stationNotFound").toString());
          navigate("/");
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
        navigate("/");
      });
  };

  const handleSubmit = async (email) => {
    let charityAmount = 0;
    setEmail(email);
    if (allowCharity) {
      if (
        donationType &&
        donationType.toUpperCase() === Common.DonationType.TOPUP
      ) {
        charityAmount = Number(donationValue?.toFixed(2));
      }

      setShowCharityModal(true);
      setCharityAmount(charityAmount);
    } else {
      doPayment(email, false);
    }
  };

  const hideCharityModal = () => {
    setShowCharityModal(false);
  };

  const doPayment = async (email: string, includeCharity: boolean) => {
    if (connector) {
      const inputData = {
        connector_id: Number(connector),
        email,
        include_charity: includeCharity,
      };
      const paymentInvoice = await createPaymentInvoice(inputData);
      if (paymentInvoice) {
        (document as any)
          .getElementById("loading")
          .classList.remove("spinner-disable");
        const isTestMode =
          paymentInvoice.isTestMode === undefined ||
          paymentInvoice.isTestMode === null ||
          paymentInvoice.isTestMode === true
            ? true
            : false;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = getDNAPaymentScript(isTestMode);
        script.nonce = "ils+vsV+OAN1z2QUGBzoyQ==";
        script.async = true;
        script.onload = () => {
          (window as any).DNAPayments.configure({
            autoRedirectDelayInMs: 3000,
            scopes: {
              allowHosted: true,
            },
            paymentMethods: [
              {
                name: (window as any).DNAPayments.paymentMethods.BankCard,
              },
            ],
            isTestMode: isTestMode,
            paymentTimeoutInSeconds: 900,
          });

          const invoiceId = paymentInvoice.invoice_id;

          const paymentData = {
            paymentSettings: {
              terminalId: paymentInvoice.terminal,
              returnUrl: `${window.location.origin}/charge-station/${code}/${connector}/payment/success/${invoiceId}`,
              failureReturnUrl: `${window.location.origin}/charge-station/${code}/${connector}/payment/fail/${invoiceId}`,
              callbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/web-pay/payment/dna-webhook/${invoiceId}`,
              failureCallbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/web-pay/payment/dna-webhook/${invoiceId}`,
              cscMatrix: 22,
              paMatrix: 3,
            },
            customerDetails: {
              accountDetails: {
                accountId: invoiceId,
              },
            },
            periodic: {
              periodicType: "ucof",
            },
            invoiceId: invoiceId,
            amount: +paymentInvoice.amount,
            auth: { access_token: paymentInvoice.access_token },
            language: "en",
            description: "Pre auth to InstaVolt",
            transactionType: paymentInvoice.transaction_type || "PRE-AUTH",
            currency: paymentInvoice.currency,
          };
          localStorage.setItem("email", email);
          (window as any).DNAPayments.openPaymentPage(paymentData);
        };

        script.onerror = () => {
          (document as any)
            .getElementById("loading")
            .classList.add("spinner-disable");
          toast.error(t("common.errors.somethingWentWrong").toString());
        };
        document.body.appendChild(script);
      }
    }
  };
  const createPaymentInvoice = async (inputData) => {
    let paymentInvoice: any = "";
    await paymentService
      .createPaymentInvoice(
        +inputData.connector_id,
        inputData.email,
        inputData.include_charity
      )
      .then(async (res: StandardResponse<IPaymentInvoice>) => {
        if (res.Data) {
          paymentInvoice = res.Data;
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
    return paymentInvoice;
  };
  return (
    <>
      <div className="start-charge">
        <button
          title={t<string>("stationDetails.button.back")}
          type="button"
          className="btn btn-icon pr-0 m-15p btn-outline-primary back-button float-right"
          onClick={() => navigate(-1)}
        >
          <img
            className="icon back-button-icon"
            src={require("../../assets/images/back-arrow.svg").default}
            alt={t<string>("stationDetails.button.back")}
          />
        </button>
        <Formik
          initialValues={initialState}
          onSubmit={(values) => {
            handleSubmit(values.email);
          }}
          validationSchema={StartChargeSchema(t)}
        >
          {(props) => {
            const { handleSubmit, handleChange, values, errors } = props;
            return (
              <div>
                <form
                  className="form-primary"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <h4 className="text-left">
                    Start Charge:{" "}
                    {stationDetails && stationDetails.charge_station_name} -{" "}
                    {connectorDetails &&
                      `${connectorDetails.title} ${connectorDetails.voltage}`}
                  </h4>
                  <Field
                    type="text"
                    name="email"
                    id="Email"
                    placeholder={t("startCharge.form.email")}
                    component={ReactstrapInput}
                    maxLength={50}
                  />
                  <div className="form-group mb-15p mt-1 ta-l">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input
                        type="checkbox"
                        id="tnc"
                        name="tnc"
                        value="tnc"
                        className="custom-control-input"
                        checked={values.tnc}
                        onChange={handleChange}
                        data-testid="tnc"
                      />
                      <label
                        className="custom-control-label pb-0 tx-bold-black"
                        htmlFor="tnc"
                      >
                        <>
                          {t("startCharge.label.accept")}{" "}
                          <a
                            href={tncLink}
                            rel="noreferrer"
                            className="link-primary"
                            target="_blank"
                          >
                            <>{t("startCharge.label.tnc")}</>
                          </a>
                        </>
                      </label>
                    </div>
                    {errors && errors.tnc && (
                      <div className="invalid-feedback error d-block p-0">
                        {errors.tnc}
                      </div>
                    )}
                  </div>
                  <Form.Group className="form-group">
                    <Button type="submit" className="btn btn-primary w-100">
                      <>{t("startCharge.button.continue")}</>
                    </Button>
                  </Form.Group>
                  <div className="alert-primary p-15p mb-4">
                    <span>
                      {t("startCharge.label.preAuthNote").replace(
                        "{preAuthAmount}",
                        preAuthAmount.toString()
                      )}
                    </span>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>
      </div>
      {showCharityModal && (
        <CharityDonationModal
          t={t}
          charityImage={charityImage}
          charityLink={charityLink}
          charityText={charityText}
          showModal={showCharityModal}
          isCharityTextTruncate={isCharityTextTruncate}
          donationNote={donationNote}
          charityAmount={charityAmount}
          onHide={() => hideCharityModal()}
          openDNAPaymentInterface={(isEnableDonation) =>
            doPayment(email, isEnableDonation)
          }
        />
      )}
    </>
  );
};

export default StartCharge;
