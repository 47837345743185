import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from "./en";

const resources = {
  en: {
    translation: {
      ...enLocale,
    },
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: false,
  resources,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    formatSeparator: ".",
  },
});

export default i18n;
