import React, { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import SearchStation from "./pages/home/SearchPage";
import RedirectUser from "./pages/station-details/RedirectUser";
import StationDetails from "./pages/station-details/StationDetails";
import { withTranslation } from "react-i18next";
import Spinner from "./layout/spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonService from "./services/common/common-service";
import { Common } from "./utils/constants";
import { MainLayout } from "./layout";
import PaymentFail from "./pages/payment/PaymentFail";
import StartCharge from "./pages/start-charge/StartCharge";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import { ICommonProps } from "./types/propTypes";
import Charging from "./pages/charging";
import DownloadVATReceipt from "./pages/downloadVATReceipt/DownloadVATReceipt";

const commonService = new CommonService();

const App: FC<ICommonProps> = (props) => {
  const { t } = props;
  const [cookiePolicyURL, setCookiePolicyURL] = useState("");

  useEffect(() => {
    const getCookiePolicyURL = async () => {
      await commonService
        .getConfigurationDetail({
          code: Common.ConfigurationKey.COOKIE_POLICY_URL,
        })
        .then((res) => {
          setCookiePolicyURL(res.Data[0]?.value);
        })
        .catch((e) => {
          console.error("error", e);
        });
    };

    getCookiePolicyURL();
  }, []);

  return (
    <div className="wrapper">
      <Routes>
        <Route path="" element={<SearchStation {...props} />} />
        <Route path="/:code" element={<RedirectUser {...props} />} />
        <Route
          path="/charge-station/:code"
          element={
            <MainLayout {...props}>
              <StationDetails {...props} />
            </MainLayout>
          }
        />
        <Route
          path="/charge-station/:code/:connector_id/payment/fail/:invoice_id"
          element={
            <MainLayout {...props}>
              <PaymentFail {...props} />
            </MainLayout>
          }
        />
        <Route
          path="/charge-station/:code/:connector_id/payment/success/:invoice_id"
          element={
            <MainLayout {...props}>
              <PaymentSuccess {...props} />
            </MainLayout>
          }
        />
        <Route
          path="/charge-station/:code/:connector_id/:invoice_id/charge"
          element={
            <MainLayout {...props}>
              <Charging {...props} />
            </MainLayout>
          }
        />
        <Route
          path="/charge-station/:code/start-charge/:connector"
          element={
            <MainLayout {...props}>
              <StartCharge {...props} />
            </MainLayout>
          }
        />
        <Route
          path="/receipt/download/:session_id"
          element={<DownloadVATReceipt {...props} />}
        />
      </Routes>
      <Spinner />
      <ToastContainer />

      <CookieConsent
        buttonText={t<string>("cookiePolicy.iAccept")}
        expires={30}
      >
        <div className="cookie-policy">
          {t<string>("cookiePolicy.text")}{" "}
          <a
            href={cookiePolicyURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "white",
              textDecoration: "underline",
            }}
            data-testid="cookie-policy"
          >
            {t<string>("cookiePolicy.action")}
          </a>
        </div>
      </CookieConsent>
    </div>
  );
};

export default withTranslation()(App);
