import StandardResponse from "models/standard-response";
import BaseService from "services/base-service";

export default class CommonService {
  async getConfigurationDetail(data: any) {
    try {
      const res = await BaseService({
        url: `web-pay/configuration/public/search`,
        method: "POST",
        data: { ...data, hideSpinner: true },
      });
      return new StandardResponse<any>(
        res.status,
        res.data.data,
        res.data.message,
        null
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
