import * as Yup from "yup";

export const CardValidationSchema = () => {
  return Yup.object().shape({
    card_number: Yup.string()
      .nullable()
      .required("required")
      .test("length", "Value length must be 4 digits", (val) => {
        if (val && val.length === 4) {
          return true;
        }
        return false;
      }),
  });
};
