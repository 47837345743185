import axios, { InternalAxiosRequestConfig } from "axios";

const BaseService = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/`,
});

BaseService.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (config && config.data && !config.data.hideSpinner) {
      document.getElementById("loading").classList.remove("spinner-disable");
    }
    if (
      config &&
      config.data &&
      Object.keys(config.data).some((key) => key === "hideSpinner")
    ) {
      delete config.data.hideSpinner;
    }

    return config;
  },
  (error) => {
    document.getElementById("loading").classList.remove("spinner-disable");
    Promise.reject(error);
  }
);

// Response interceptors Customize based on your need
BaseService.interceptors.response.use(
  (response) => {
    document.getElementById("loading").classList.add("spinner-disable");
    return response;
  },
  async (error) => {
    switch (error.response && error.response.status) {
      case 401:
        // Authorization Failed Response can add other status codes here to manage error Logging
        break;
      case 403:
        break;
      default:
        break;
    }
    document.getElementById("loading").classList.add("spinner-disable");
    return Promise.reject(error);
  }
);

export default BaseService;
