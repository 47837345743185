import React, { useEffect } from "react";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ICommonProps } from "../../types/propTypes";

const RedirectUser: FC<ICommonProps> = () => {
  let { code } = useParams();
  useEffect(() => {
    code = code !== null ? code : "";
    const baseURL = `${window.location.origin}/charge-station/${code}`;
    window.location.href = baseURL;
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>InstaVolt - Rapid EV Charging UK</title>
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="instavolt" />
      </Helmet>
      <div
        className="card-body text-center"
        style={{ width: "100%", background: "white", height: "100vh" }}
      >
        <div className="row min-vh-50 align-items-center">
          <div className="col">
            <div className="mesg-box process-block text-center">
              <img src={require("../../assets/images/process.gif")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedirectUser;
